<template>
  <div class="credCoupon">
    <h1 @click="shouClick">返回<span>></span>已绑定</h1>
    <div class="credCoupon_banner">
      <div class="banner_conner" v-for="item in bandCard" :key="item.id">
        <h2>
          <i></i>
          {{ item.cardCode }}
        </h2>
        <div class="timeBind">
          <div>绑定时间：{{ item.bindingTime }}</div>
          <div>到期时间：{{ item.expirationDate }}</div>
        </div>
        <div class="year">{{ item.fixedYear }} {{ item.unit }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getcheckCard, getbindCard, findPlusContextual } from "api/service";
export default {
  data() {
    return {
      QrCodeVisible: false,
      meanShow: true,
      chaxShow: true,
      show_pass: false,
      //   搜索内容
      inpuValuet: "",
      passwordValuet: "",
      credValuet: "",
      title: "卡券查询",
      sty: {},
      // 绑定卡信息
      bandCard: [],
    };
  },
  created() {
    this.findPlusContextual();
  },
  methods: {
    // 获取已绑定的卡号
    async findPlusContextual() {
      let data = await findPlusContextual();
      this.bandCard = data.data;
    },
    shouClick() {
      this.$router.go(-1);
    },
    // 已绑定
    boundClick() {
      this.$router.push({
        name: "bound",
      });
    },
    // 取消
    backClick() {
      this.QrCodeVisible = false;
      this.inpuValuet = "";
      this.passwordValuet = "";
      this.credValuet = "";
      this.meanShow = true;
    },
    async confim() {
      if (this.chaxShow) {
        if (!this.inpuValuet) {
          return this.$message({
            type: "error",
            message: "请输入券号！",
          });
        }
        let data = await getcheckCard({ cardCode: this.inpuValuet });
        if (data.code == 10004) {
          this.inpuValuet = "";
          return this.$message({
            type: "error",
            message: data.message,
          });
        } else {
          this.sty = data.data;
          this.meanShow = false;
          this.title = "查询结果";
        }
        this.inpuValuet = "";
      } else {
        if (!this.credValuet) {
          return this.$message({
            type: "error",
            message: "请输入券号！",
          });
        }
        if (!this.passwordValuet) {
          return this.$message({
            type: "error",
            message: "请输入密码！",
          });
        }
        let params = {
          cardCode: this.credValuet,
          cardPassword: this.passwordValuet,
        };
        let data = await getbindCard(params);
        if (data.code == 200) {
          this.$message({
            type: "succses",
            message: data.message,
          });
          this.credValuet = "";
          this.passwordValuet = "";
          this.QrCodeVisible = false;
        } else {
          this.$message({
            type: "error",
            message: data.message,
          });
        }
      }
    },
    // 搜索
    searchClick() {
      this.chaxShow = true;
      this.QrCodeVisible = true;
    },
    bindClick() {
      this.chaxShow = false;
      this.QrCodeVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.credCoupon {
  min-width: 758px;
  height: calc(100vh - 60px);
  margin: 0 auto;
  background-color: #fff;
  h1 {
    font-size: 14px;
    height: 21px;
    line-height: 21px;
    font-family: PingFang SC;
    font-weight: 500;
    margin: 30px 0 0 50px;
    color: #333333;
    border-left: 3px solid #4587ff;
    padding-left: 11px;
    span {
      margin: 0 20px;
    }
  }
  .credCoupon_banner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .banner_conner {
      position: relative;
      width: 323px;
      height: 125px;
      margin: 13px 0 0 50px;
      padding: 22px 33px 33px 22px;
      box-sizing: border-box;
      background: url("~assets/banner_1.png") no-repeat;
      background-size: 100% 100%;
      h2 {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #4587ff;
        line-height: 14px;
        i {
          display: inline-block;

          width: 6px;
          height: 6px;
          background: #4587ff;
          border-radius: 50%;
          margin-right: 6px;
          margin-bottom: 2px;
        }
      }
      .timeBind {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 40px;
      }
      .year {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
</style>
